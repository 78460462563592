import React from 'react';
import ReactPlayer from 'react-player';

  const Section6 = () => {
    return (
      <div className="h-screen bg-[url('/public/photo_montage_video.jpg')] bg-cover bg-center">
        <div className='flex flex-col justify-center h-full '>
            <h className="font-serif font-bold text-5xl lg:text-7xl text-white text-center mb-4">Contact</h>
            <h className="font-sans text-base lg:text-xl text-base font-light text-white text-center">voici.le.soleil.films@gmail.com <br />
06 44 39 55 98 </h>
        </div>
      </div>
    );
  };
  
  export default Section6;