import React from 'react';

const Section2 = () => {
  return (
    <div className="flex items-center justify-center h-screen p-8">
      <div className="max-w-4xl mx-auto px-10">
        <p className=" text-4xl lg:text-6xl font-serif text-gray-800 leading-relaxed mb-4 ">
          “Le soleil est pour tout le monde ; pour tous, il éclaire et réchauffe.”
        </p>
        <p className="text-gray-600 text-lg text-right">
          — Voltaire, <em>Candide</em>
        </p>
      </div>
    </div>
  );
};

export default Section2;