import React from 'react';
import ReactPlayer from 'react-player';

  const Section5 = () => {
    return (
      <div className="h-screen bg-[url('/public/20230708-photo_discretion-2.jpg')] bg-cover bg-center">
        <div className='flex flex-col justify-center h-full '>
            <h className="font-serif font-bold text-3xl text-white text-center mb-4">authenticité & discrétion</h>
            <h className="font-sans text-xs sm:text-base lg:text-xl font-light text-white text-center">Mon but est de capturer des moments authentiques, <br />
pour enfin construire une histoire structurée au montage.<br /> <br />
Je veux être le plus discret possible pour capter les plus beaux sourires,<br />
les plus belles larmes, sans que personne ne le remarque.<br /><br />
Je cherche le minimalisme car il est intemporel et touchant.</h>
        </div>
      </div>
    );
  };
  
  export default Section5;