import React from 'react';

const Section3 = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-creme p-8">
      <div className="max-w-3xl mx-auto text-center">
        <p className="text-xl lg:text-3xl font-serif text-gray-700 leading-relaxed">
          <strong>voici le soleil</strong> est une maison de production de <strong>films de mariage</strong> basée à Grenoble. Inspirée par la beauté du cinéma et l’harmonie du jazz, elle a pour objectif de créer un <strong>souvenir unique, intemporel et authentique</strong> de votre mariage.
        </p>
      </div>
    </div>
  );
};

export default Section3;