import React from 'react';
import ReactPlayer from 'react-player';

const Section1 = () => {
    return (
      <div className="relative h-screen bg-black">
        <video className="absolute inset-0 w-full h-full object-cover" autoPlay loop muted playsInline>
          <source src="/video_VLS.mp4" type="video/mp4" />
        </video>
        <div className="relative z-10 flex justify-center">
          <img src="/logo_VLS.png" alt="Logo 1" className="w-96 lg:w-128 h-auto mt-4" />
        </div>
      </div>
    );
  };
  
export default Section1;
